import React from 'react';
import TutorialList from '../../components/common/tutorials/TutorialList';

const Tutorials: React.FC = () => {
    return (
        <div>
            <TutorialList canEdit={false} />
        </div>
    );
}

export default Tutorials;
