import React, { useContext } from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { GoogleClientId } from '../../../../config';
import { UserContext } from '../../../../conexts/UserContext';
import { UserContextType } from '../../../../types/UserType';
import { FaGoogle } from "react-icons/fa";
import GoogleSignInComponent from './GoogleSignInComponent';

interface Props {
    setWarning: React.Dispatch<React.SetStateAction<string>>;
}

const GoogleLoginButton: React.FC<Props> = ({ setWarning }) => {
    const { SingInWithGoogle } = useContext(UserContext) as UserContextType;

    const onSuccess = async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        const googleRes = res as GoogleLoginResponse;

        const response = await SingInWithGoogle(googleRes.accessToken);
        if (!response) {
            setWarning("Error hapened, maybe user with same email already exists");
        }
    }

    const onFailure = (error: Error) => {
        setWarning("Unknown error, when sign in with google");
    }

    return (
        <div className='google-signin-button-wrapper'>
            <GoogleSignInComponent />
            <GoogleLogin
                clientId={GoogleClientId}
                render={renderProps => (
                    <button className='google-signin-button icon-button clickable' onClick={renderProps.onClick} disabled={renderProps.disabled}>
                        <FaGoogle />
                        <span>Sign in with Google</span>
                    </button>
                )}
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy='single_host_origin'
                isSignedIn={true}
            />
        </div>
    );
}

export default GoogleLoginButton;
