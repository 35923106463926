import React, { useState } from 'react';
import { Video } from '../../../types/VideoType';
import { IoMdAdd } from "react-icons/io";
import TutorialModal from './TutorialModal';
import ModalWindow from '../General/ModalWindow';

interface Props {
    setTutorials: React.Dispatch<React.SetStateAction<Video[]>>;
}

const AddTutorialButton: React.FC<Props> = ({ setTutorials }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [video, setVideo] = useState<Video>({
        title: "",
        description: "",
        preview: "",
        video_link: "",
        zip: "",
        duration: "",
        viewed_count: 0
    });

    return (
        <div className='add-tutorial-button'>
            <div className="add-tutorial-circle clickable" onClick={() => { setIsModalOpen(true) }}>
                <IoMdAdd />
            </div>

            {isModalOpen &&
                <ModalWindow OnCloseModal={() => setIsModalOpen(false)} modalWidth={1300}>
                    <TutorialModal closeModal={() => { setIsModalOpen(false) }} video={video} setVideo={(video: Video) => {
                        setVideo(video)
                    }} isEdit={false} setTutorials={setTutorials} />
                </ModalWindow>
            }
        </div>
    );
}

export default AddTutorialButton;
