import React, { useState } from 'react';
import UploadFileButton from '../../../common/UI/Buttons/UploadFileButton';

interface Props {
    Avatar: string | null | undefined;
    setAvatar: (b64Image: string) => void;
}

const AvatarChoose: React.FC<Props> = ({ Avatar, setAvatar }) => {
    const [b64Prefix, setB64Prefix] = useState<string>("");
    const [warn, setWarn] = useState("");

    return (
        <div className='choose-avatar'>
            <div className="profile-avatar" style={{ width: 200, height: 200, marginBottom: 25 }}>
                {Avatar && <img src={b64Prefix + Avatar} className='avatar' alt="Profile avatar" height={200} width={200} />}
            </div>

            <UploadFileButton
                availableTypes={["image/jpeg", "image/jpg", "image/png", "image/gif",]}
                MaximumFileSize={4096}
                setB64Prefix={setB64Prefix}
                setFile={setAvatar}
                setWarning={setWarn}
                buttonLabel="Upload avatar" />

            <p style={{ textWrap: 'wrap' }} className='warning'>{warn}</p>
        </div>
    );
}

export default AvatarChoose;
