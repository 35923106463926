import React from 'react';

interface Props {
    src: string;
    alt: string;
    height: number;
    width: number;
}

const Avatar: React.FC<Props> = ({ src, alt, height, width }) => {
    return (
        <div className='avatar-wrapper clickable'>
            <img className='avatar' src={src} alt={alt} width={width} height={height} />
        </div>
    );
}

export default Avatar;
