import React from 'react';
import { Link } from 'react-router-dom';
import "./UITextStyles.css";

interface Props {
    path: string;
    label: string;
};

const BoldLink: React.FC<Props> = ({ path, label }) => {
    return (
        <div className="link__wrapper clickable" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            <Link className='bold-link' to={path} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                <span style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{label}</span>
            </Link>
        </div>
    );

}

export default BoldLink;
