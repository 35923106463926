import React from 'react';
import { GitHubClientId } from '../../../../config';
import { FaGithub } from "react-icons/fa";
import { Domain } from '../../../../config';


const GitHubLoginButton: React.FC = () => {
    return (
        <div>
            <button className='github-signin-button icon-button clickable' onClick={() => {
                localStorage.setItem('lastPage', window.location.pathname);

                const redirectUri = `${Domain}/auth/github/callback`;
                window.location.href = `https://github.com/login/oauth/authorize?client_id=${GitHubClientId}&redirect_uri=${redirectUri}`;
            }}>
                <FaGithub />
                <span>Sign in with GitHub</span>
            </button>
        </div>
    );
}

export default GitHubLoginButton;
