import { gapi } from 'gapi-script';
import React, { useEffect } from 'react';
import { GoogleClientId } from '../../../../config';

const GoogleSignInComponent = () => {
    useEffect(() => {
        gapi.load('client:auth2', () => {
            gapi.client.init({
                clientId: GoogleClientId,
                scope: ''
            })

        })
    })

    return (
        <div>

        </div>
    );
}

export default GoogleSignInComponent;
