import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminTutorials from '../pages/tutorials/AdminTutorials';
import { UserContext } from '../conexts/UserContext';
import { UserContextType } from '../types/UserType';


const AdminRoute: React.FC = () => {
    const { user } = useContext(UserContext) as UserContextType;

    useEffect(() => {
        if (user && !user.isAdmin) {
            localStorage.setItem("notAdminError", "1");
            window.location.href = '/';
        }
    }, [user])

    return (
        <Routes>
            <Route path='/tutorials' element={<AdminTutorials />} />
        </Routes>
    )
}

export default AdminRoute;
