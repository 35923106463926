import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../conexts/UserContext';
import { UserContextType } from '../../types/UserType';

const GitHubRedirect: React.FC = () => {
    const { user, SignInWithGitHub } = useContext(UserContext) as UserContextType;

    useEffect(() => {
        const signIn = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code: string | null = urlParams.get('code');

            if (code) {
                await SignInWithGitHub(code);
            }
        }

        signIn();
    });

    useEffect(() => {
        if (user) {
            const lastPage = localStorage.getItem('lastPage');
            if (lastPage)
                window.location.pathname = lastPage;
            else
                window.location.pathname = '/';
        }

    }, [user])

    return null;
}

export default GitHubRedirect;
