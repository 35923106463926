import React from 'react';
import "./UIButtonsStyles.css"

interface Props {
    label: string,
    onClick: () => void;
}

const DangerButton: React.FC<Props> = ({ label, onClick }) => {
    return (
        <button onClick={onClick} className='danger-button button clickable' >
            {label}
        </button>
    );
}

export default DangerButton;
