import { ApiDomain } from "../../config";
import { NotCreatedVideo, Video, VideosPaginatiopnResponse } from "../../types/VideoType";

export const GetTutorialById = async (id: number) => {
    const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    };

    const response = await fetch(`${ApiDomain}/api/videos/single?id=${id}`, options);
    if (!response.ok) return null
    return await response.json() as Video;
}

export const GetTutorials = async (pageIndex: number, pageSize: number) => {
    const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    };

    const response = await fetch(`${ApiDomain}/api/videos?pageIndex=${pageIndex}&pageSize=${pageSize}`, options);
    return await response.json() as VideosPaginatiopnResponse;
}

export const AddTutorial = async (video: NotCreatedVideo) => {

    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(video),
        credentials: 'include' as RequestCredentials
    };


    const response = await fetch(`${ApiDomain}/api/videos`, options);
    if (!response.ok) {
        return null;
    }

    return await response.json() as Video;
}
export const UpdateTutorial = async (video: Video) => {

    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(video),
        credentials: 'include' as RequestCredentials
    };


    return await fetch(`${ApiDomain}/api/videos`, options);
}

export const DeleteTutorial = async (id: number) => {
    const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
        credentials: 'include' as RequestCredentials
    };

    return await fetch(`${ApiDomain}/api/videos/${id}`, options);
}

