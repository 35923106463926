import React, { useContext, useEffect } from 'react';
import { AlertsContext } from '../../conexts/AlertsContext';
import { Alert, AlertsContextType } from '../../types/UI/AlertsContextType';
import { FaYoutube } from 'react-icons/fa';

const Home = () => {
    const { AddAlert } = useContext(AlertsContext) as AlertsContextType;

    useEffect(() => {
        if (localStorage.getItem("isJustUserDeleted")) {
            const alert: Alert = {
                title: "Delete user",
                type: 'danger',
                content: "User was successfully deleted"
            }

            AddAlert(alert);
            localStorage.removeItem("isJustUserDeleted");
        }
        else if (localStorage.getItem("notAdminError")) {
            const alert: Alert = {
                title: "Admin access",
                type: 'danger',
                content: "You don't have access to admin panel"
            }

            AddAlert(alert);
            localStorage.removeItem("notAdminError");
        }
    })

    return (
        <div className='page' style={{ textAlign: 'center' }}>
            <h1> Misha.dev Diary Channel <br />Home page
            </h1>
            <a target='_blank' href='https://www.youtube.com/channel/UCJjA2Im7Vv0WzHBFdULLsOg' className="footer-icon-youtube">
                <FaYoutube size={50} />
            </a>
        </div>
    );
}

export default Home;
