import React from 'react';
import { Routes, Route } from 'react-router-dom';
import GitHubRedirect from '../pages/auth/GitHubRedirect';

const AuthRoute: React.FC = () => {
    return (
        <Routes>
            <Route path='/github/callback' element={<GitHubRedirect />} />
        </Routes>
    )
}

export default AuthRoute;