import React, { useEffect, useRef, useState } from 'react';
import "./Tutorials.css";
import { Video, VideosPaginatiopnResponse } from '../../../types/VideoType';
import AddTutorialButton from '../../specific/Tutorials/AddTutorialButton';
import { GetTutorials } from '../../../services/tutorials/TutorialsService';
import TutorialItem from './TutorialItem';
import TutorialModal from '../../specific/Tutorials/TutorialModal';

interface Props {
    canEdit: boolean
}
const pageSize: number = 20;

const TutorialList: React.FC<Props> = ({ canEdit }) => {
    const [tutorials, setTutorials] = useState<Video[]>([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [load, setLoad] = useState<boolean>(false);

    const renderAfterCalled = useRef(false);

    useEffect(() => {
        if (!renderAfterCalled.current || load) {

            if (isFetching || !hasNextPage) return;

            const fetchNewVideos = async () => {
                setIsFetching(true);

                const nextTutorials: VideosPaginatiopnResponse = await GetTutorials(pageIndex, pageSize);
                setTutorials(prevTutorials => [...prevTutorials, ...nextTutorials.items]);
                setHasNextPage(nextTutorials.hasNextPage);

                setIsFetching(false);
            }

            fetchNewVideos();
        }

        renderAfterCalled.current = true;
    }, [pageIndex])

    useEffect(() => {
        const win: Window = window;
        win.addEventListener("scroll", handleScroll);
        window.addEventListener('load', () => { setLoad(true); renderAfterCalled.current = false; })
        return () => win.removeEventListener("scroll", handleScroll);
    }, [pageIndex]);

    const handleScroll = async (e: Event) => {
        const target = e.target as Document;
        if ((target.documentElement.scrollTop + window.innerHeight + 1) >= target.documentElement.scrollHeight) {
            setPageIndex(prevState => prevState + 1);
        }
    };

    return (
        <div className='tutorials-list'>
            {canEdit && <AddTutorialButton setTutorials={setTutorials} />}

            {tutorials.map((video) => (
                <TutorialItem key={video.id} video={video} canEdit={canEdit} setTutorials={setTutorials} />
            ))}

        </div>
    );
}

export default TutorialList;
