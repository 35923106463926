import React, { useState } from 'react';
import "../SpecificComponentStyles.css"
import ModalWindow from '../General/ModalWindow';
import { CurrentModals } from '../../../types/UI/CurrentModals';
import GoogleLoginButton from './Google/GoogleLoginButton';
import GitHubLoginButton from './GitHub/GitHubLoginButton';
import DefaultLink from '../../common/UI/Text/DefaultLink';

interface Props {
    modalOpen: CurrentModals;
    setModalOpen: React.Dispatch<React.SetStateAction<CurrentModals>>
}

const SignInModal: React.FC<Props> = ({ modalOpen, setModalOpen }) => {
    const [warning, setWarning] = useState<string>("");

    const OnCloseModal = () => {
        setModalOpen(prevState => ({
            ...prevState,
            SignIn: false
        }));
    }


    return modalOpen.SignIn ? (
        <ModalWindow OnCloseModal={OnCloseModal} modalWidth={400}>
            <div className="signin-modal-body">
                <h2>Sign in</h2>

                <GoogleLoginButton setWarning={setWarning} />
                <GitHubLoginButton />

                {warning && <p className="warning">{warning}</p>}

                <p className="detail" style={{ marginTop: 25 }}>
                    By singing in or creating account you agree with <DefaultLink path='/privacy-policy' label='Privacy policy' />  and <DefaultLink path='/Terms-of-service' label='Terms of service' />
                </p>
            </div>
        </ModalWindow>
    ) : null;
}

export default SignInModal;
