import React, { useState } from 'react';
import { MdModeEditOutline } from "react-icons/md";

interface Props {
    PropertyName: string,
    Property: string | null,
    setProperty: (newValue: string) => void
    Editable: boolean;
}

const UserPropertyInput: React.FC<Props> = ({ PropertyName, Property, setProperty, Editable }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);

    return (
        <div className="profile-info-field">
            <span>
                <b className='property-name'>{PropertyName}:</b> {isEditing ?
                    <input className='form-field' onKeyDown={(e) => { if (e.key === 'Enter') setIsEditing(!isEditing); }} onBlur={() => setIsEditing(!isEditing)} type="text" value={Property || ""} onChange={(e) => { setProperty(e.target.value) }} />
                    : <span className='profile-info-field__value'>{Property}</span>}
            </span>

            {Editable && <div className="edit-field clickable" onClick={() => setIsEditing(!isEditing)}>
                <MdModeEditOutline size={30} />
            </div>}
        </div>
    );
}

export default UserPropertyInput;
