import React from 'react';
import loadingSpinner from "../../../Assets/Image/loadingSpinner.gif";

const Loading = () => {
    return (
        <div className='loading-screen'>
            <div className="loading-content">
                <h1>Loading...</h1>
                <img src={loadingSpinner} alt="loading" />
            </div>
        </div>
    );
}

export default Loading;
