import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../conexts/UserContext';
import { IUser, UserContextType } from '../../types/UserType';
import AvatarChoose from '../../components/specific/Pages/Profile/AvatarChoose';
import UserPropertyInput from '../../components/specific/Pages/Profile/UserPropertyInput';
import "./HomeStyles.css";
import SecondaryButton from '../../components/common/UI/Buttons/SecondaryButton';
import { DeleteUser, UpdateUser } from '../../services/user/UserServices';
import DangerButton from '../../components/common/UI/Buttons/DangerButton';
import PrimaryButton from '../../components/common/UI/Buttons/PrimaryButton';
import { GoogleClientId } from '../../config';
import { useGoogleLogout } from 'react-google-login';
import { AlertsContext } from '../../conexts/AlertsContext';
import { Alert, AlertsContextType } from '../../types/UI/AlertsContextType';

const Profile = () => {
    const { user, UpdateUserInfo } = useContext(UserContext) as UserContextType;
    const { AddAlert } = useContext(AlertsContext) as AlertsContextType;

    const [newUser, setNewUser] = useState<IUser | null>(null);
    const [warning, setWarning] = useState<string>("");
    const [deleteProfileWarning, setDeleteProfileWarning] = useState<boolean>(false);

    const { signOut: GoogleSignOut } = useGoogleLogout({
        clientId: GoogleClientId
    })

    useEffect(() => {
        if (user) {
            setNewUser(user);
        } else {
            if (deleteProfileWarning) {
                window.location.href = '/';
            }
        }
    }, [user, deleteProfileWarning]);

    const SaveChanges = async () => {
        if (newUser?.Name && newUser?.Name?.length > 100) {
            setWarning("Maximum name length is 100 letters");
            return;
        }
        if (!newUser) {
            setWarning("User has no data");
            return;
        }

        const response: boolean = await UpdateUser(newUser);
        await UpdateUserInfo();

        if (!response) {
            setWarning("Unknown error hapened, try one more time");
        } else {
            const alert: Alert = {
                title: "Save changes",
                type: 'success',
                content: "Changes was successfully saved"
            }

            AddAlert(alert);
        }
    }

    const DeleteProfile = async () => {
        if (user?.Provider === "google") GoogleSignOut();
        const response = await DeleteUser();

        if (!response) {
            setWarning("Unknown error");
        } else {
            localStorage.setItem("isJustUserDeleted", "1");
            await UpdateUserInfo();
        }
    }

    return newUser ? (
        <div className='page'>
            <div className="profile-page">
                <h1 style={{ marginBottom: 40 }}>Profile</h1>

                <div className="profile-properties">
                    <AvatarChoose Avatar={newUser.Avatar} setAvatar={(b64Image: string) => { setNewUser({ ...newUser, Avatar: b64Image }) }} />
                    <div className="profile-info">
                        <UserPropertyInput Editable={false} PropertyName='Email' Property={newUser.Email} setProperty={(newValue: string) => { }} />
                        <UserPropertyInput Editable={true} PropertyName='Name' Property={newUser.Name} setProperty={(newValue: string) => { setNewUser({ ...newUser, Name: newValue }) }} />
                        <UserPropertyInput Editable={false} PropertyName='Registered at' Property={`${newUser.CreatedAt.toDateString()}`} setProperty={(newValue: string) => { }} />
                        <p className="warning">{warning}</p>
                    </div>
                </div>

                <div className="save-changes-button" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20, marginRight: 50 }}>
                    <SecondaryButton label='Save changes' onClick={SaveChanges} />
                </div>
                <div className="save-changes-button" style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 50 }}>

                    {deleteProfileWarning ? <div className="delete-profile-warning">
                        <p className="warning">Are you sure?</p>
                        <PrimaryButton label='No' onClick={() => setDeleteProfileWarning(false)} />
                        <DangerButton label='Yes, Delete' onClick={DeleteProfile} />
                    </div> : <DangerButton label='Delete profile' onClick={() => setDeleteProfileWarning(true)} />}
                </div>
            </div>
        </div>
    ) : (
        <h1>This page available only to signed in users</h1>
    );
}

export default Profile;
