import React, { useRef } from 'react';
import { IoMdClose } from "react-icons/io";

interface Props {
    children: React.ReactNode;
    OnCloseModal: () => void;
    modalWidth: number;
};

const ModalWindow: React.FC<Props> = ({ children, OnCloseModal, modalWidth }) => {
    const modalRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            OnCloseModal();
        }
    };


    return (
        <div className="modal-blur" onClick={handleOutsideClick}>
            <div ref={modalRef} className='modal-wrapper' style={window.innerWidth <= modalWidth + 50 ? { width: "80%" } : { width: modalWidth }}>
                <div className="close-modal clickable" onClick={OnCloseModal}>
                    <IoMdClose color='#000' />
                </div>
                <div className="modal">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default ModalWindow;
