import React, { useRef } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';

interface Props {
    availableTypes: string[];
    MaximumFileSize: number;
    setB64Prefix: (newPrefix: string) => void;
    setFile: (b64string: string) => void;
    setWarning: (warning: string) => void;
    buttonLabel: string;
}

const UploadFileButton: React.FC<Props> = ({ availableTypes, MaximumFileSize, setB64Prefix, setFile, setWarning, buttonLabel }) => {
    const fileInput = useRef<HTMLInputElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = event.target.files?.[0];

        if (fileUploaded?.type && availableTypes.includes(fileUploaded.type.toLowerCase())) {
            if ((fileUploaded?.size / 1024) >= MaximumFileSize) {
                setWarning(`File too Big, please select a file less than ${Math.round(MaximumFileSize / 1024)}mb`)
            } else {
                const reader = new FileReader();
                reader.onloadend = () => {

                    const base64String = (reader.result as string).split(',')[1];
                    setB64Prefix((reader.result as string).split(',')[0] + ',')

                    setFile(base64String);
                };
                reader.readAsDataURL(fileUploaded);
                setWarning("");
            }

        } else {
            let availableTypesString = "";
            availableTypes.forEach((type) => {
                availableTypesString += type.split('/')[1] + ", ";
            })
            setWarning(`Available file types are: ${availableTypesString.slice(0, -2)}`);
        }
    };

    return (
        <div>
            <button className="icon-button button-upload clickable" onClick={(e) => {
                e.preventDefault();
                if (fileInput.current) fileInput.current.click()
            }}>
                <MdOutlineFileUpload />
                <span>{buttonLabel}</span>
            </button>

            <input
                type="file"
                onChange={handleChange}
                ref={fileInput}
                style={{ display: 'none' }}
            />
        </div>
    );
}

export default UploadFileButton;
