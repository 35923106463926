import React, { useContext, useState } from 'react'
import Avatar from '../../common/UI/Images/Avatar';
import PrimaryButton from '../../common/UI/Buttons/PrimaryButton';
import { UserContext } from '../../../conexts/UserContext';
import { UserContextType } from '../../../types/UserType';
import DangerButton from '../../common/UI/Buttons/DangerButton';
import SignInModal from './SignInModal';
import { CurrentModals } from '../../../types/UI/CurrentModals';
import { useGoogleLogout } from 'react-google-login';
import { GoogleClientId } from '../../../config';
import BoldLink from '../../common/UI/Text/BoldLink';
import { Link } from 'react-router-dom';


const UserTab: React.FC = () => {
    const { user, LogoutUser } = useContext(UserContext) as UserContextType;
    const [modalOpen, setModalOpen] = useState<CurrentModals>({
        SignIn: false,
    })

    const { signOut: GoogleSignOut } = useGoogleLogout({
        clientId: GoogleClientId,
        onLogoutSuccess: () => {
            LogoutUser();
        },
    })


    return user ? (
        <div className='user-tab' style={{ height: 50 }}>
            <BoldLink path="/profile" label="Profile" />
            <Link to="/profile">
                <Avatar src={user.Avatar || ""} alt={"user avatar"} width={50} height={50} />
            </Link>

            <DangerButton label="Log out" onClick={() => {
                if (user.Provider === 'google') {
                    GoogleSignOut();
                } else if (user.Provider === 'github') {
                    LogoutUser();
                } else {
                    LogoutUser();
                }
            }} />
        </div>
    ) : (
        <div className="user-tab">
            <PrimaryButton label="Sign in" onClick={() => { setModalOpen(prevState => ({ ...prevState, SignIn: true })) }} />

            <SignInModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
    )
}

export default UserTab;