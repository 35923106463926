import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeRoute from './routes/HomeRoute';
import Header from './components/common/Layout/Header';
import Footer from './components/common/Layout/Footer';
import UserProvider from './conexts/UserContext';
import AuthRoute from './routes/AuthRoute';
import AdminRoute from './routes/AdminRoute';
import Loading from './components/common/Layout/Loading';
import AlertsProvider from './conexts/AlertsContext';
import TutorialsRoute from './routes/TutorialsRoute';

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [loading]);

  return (
    <UserProvider>
      <AlertsProvider>
        {loading && <Loading />}
        {!loading && (
          <div className='wrapper'>
            <BrowserRouter>
              <Header />
              <div className="page-body">
                <div className="main-container">
                  <Routes>
                    <Route path='/*' element={<HomeRoute />} />
                    <Route path='/auth/*' element={<AuthRoute />} />
                    <Route path='/tutorials/*' element={<TutorialsRoute />} />
                    <Route path='/admin/*' element={<AdminRoute />} />
                  </Routes>
                </div>
              </div>
              <Footer />
            </BrowserRouter>
          </div>
        )}
      </AlertsProvider>
    </UserProvider>
  );
}

export default App;
