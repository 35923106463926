import TutorialList from '../../components/common/tutorials/TutorialList';

const AdminTutorials: React.FC = () => {
    return (
        <div className='page'>
            <TutorialList canEdit={true} />
        </div>
    );
}

export default AdminTutorials;
