import React from 'react';
import BoldLink from '../UI/Text/BoldLink';
import UserTab from '../../specific/Users/UserTab';
import "./Layout.css";
import logo from "../../../Assets/Image/logo.png";
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
    return (
        <header className="header">
            <nav className="header-container">
                <div className="header__wrapper-links">
                    <Link to="/">
                        <img src={logo} alt="logo" className='logo clickable' />
                    </Link>

                    <BoldLink path="/" label="Home" />
                    <BoldLink path="/tutorials" label="Tutorials" />
                </div>
                <div className="header__wrapper-auth">
                    <UserTab />
                </div>
            </nav>
        </header>
    );
}

export default Header;
