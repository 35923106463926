import React, { useState } from 'react';
import "./Tutorials.css";
import { Video } from '../../../types/VideoType';
import { IoTimeOutline } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import TutorialModal from '../../specific/Tutorials/TutorialModal';
import ModalWindow from '../../specific/General/ModalWindow';
import { Link } from 'react-router-dom';

interface Props {
    video: Video;
    canEdit: boolean,
    setTutorials: React.Dispatch<React.SetStateAction<Video[]>>;
}

const TutorialItem: React.FC<Props> = ({ video, canEdit, setTutorials }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updatedVideo, setUpdatedVideo] = useState<Video>(video);

    return (
        <div className='tutorials-list__item'>
            <div className="tutorials-list__item-content clickable">
                <Link to={`/tutorials/${video.id}`} >
                    <img src={video.preview.startsWith("/wwwroot") || video.preview.startsWith("http") ? video.preview : "data:image/jpg;base64," + video.preview} alt={video.title + " Preview"} width={350} />
                    <div className="tutorials-list__item-content-text">
                        <h2 className="tutorials-item-title">{video.title.length < 25 ? video.title : video.title.slice(0, 25) + "..."}</h2>
                        {video.description &&
                            <p className='tutorials-item-description'>{video.description.length > 30 ?
                                video.description.slice(0, 30) + "..." :
                                video.description}
                            </p>
                        }

                        <p className="tutorials-item-duration">
                            <IoTimeOutline size={20} />
                            <span>{video.duration}</span>
                        </p>
                    </div>
                </Link>
            </div>
            {canEdit && <div className="tutorials-list__item-actions">
                <div className="tutorial__item-action clickable" onClick={() => { setIsModalOpen(true) }}>
                    <MdEdit size={30} />
                </div>

                {isModalOpen && <ModalWindow OnCloseModal={() => { setIsModalOpen(false) }} modalWidth={1300}>
                    <TutorialModal closeModal={() => { setIsModalOpen(false) }} video={updatedVideo} isEdit={canEdit} setVideo={setUpdatedVideo} setTutorials={setTutorials} />
                </ModalWindow>}
            </div>
            }

        </div>
    );
}

export default TutorialItem;
