import React from 'react';
import { TbError404Off } from "react-icons/tb";

const NotFound: React.FC = () => {
    return (
        <div className='notfound-page'>
            <h1>Error 404 </h1>
            <h2>Page not found</h2>

            <TbError404Off size={200} color='red' />
        </div>
    );
}

export default NotFound;
