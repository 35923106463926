import React from 'react';
import { Link } from 'react-router-dom';
import "./UITextStyles.css";

interface Props {
    path: string;
    label: string;
};

const DefaultLink: React.FC<Props> = ({ path, label }) => {
    return (
        <span className="link__wrapper">
            <Link className='default-link' to={path}>
                <span>{label}</span>
            </Link>
        </span>
    );
}

export default DefaultLink;
