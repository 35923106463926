import { createContext, useEffect, useRef, useState } from "react";
import { Alert, AlertsContextType } from "../types/UI/AlertsContextType";
import "./ContextStyles.css";
import { IoMdClose } from "react-icons/io";

export const AlertsContext = createContext<AlertsContextType | null>(null);

const AlertsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [activeAlert, setActiveAlert] = useState<Alert | null>(null);
    const [showAlert, setShowAlert] = useState<boolean>(false); // New state to manage visibility
    const currentTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if (activeAlert) {
            setShowAlert(true);
            currentTimeout.current = setTimeout(() => {
                setShowAlert(false);
                setTimeout(() => { setActiveAlert(null) }, 4000);
            }, 4000);
        }
    }, [activeAlert]);

    const AddAlert = (alert: Alert) => {
        setActiveAlert(alert);
    };

    return (
        <AlertsContext.Provider value={{ AddAlert }}>
            {activeAlert && (
                <div className={`alert alert-${activeAlert.type} ${showAlert ? 'show' : 'hide'}`}>
                    <div className="alert-wrapper">
                        <h2>{activeAlert.title}</h2>
                        {activeAlert.content && <p>{activeAlert.content}</p>}
                    </div>

                    <div className="alert-close clickable" onClick={() => {
                        if (currentTimeout.current) {
                            clearTimeout(currentTimeout.current);
                            setShowAlert(false);
                            setTimeout(() => { setActiveAlert(null) }, 4000);
                        }
                    }}>
                        <IoMdClose size={30} />
                    </div>
                </div>
            )}
            {children}
        </AlertsContext.Provider>
    )
}

export default AlertsProvider;