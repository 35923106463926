import { Video } from "../../types/VideoType";

export const EmailValidation = (Email: string) => {
    if (!Email) {
        return "Email can't be empty!";
    }
    else if (Email.length > 2000) {
        return "Email must be shorter!";
    }
    else if (!Email.includes("@") || !Email.includes(".")) {
        return 'Email must contain "@" and "." symbols';
    }

    return true;
}

export const PasswordValidation = (Password: string) => {
    if (!Password) {
        return "Password can't be empty!";
    } else if (Password.length < 6 || Password.length > 15) {
        return "Password must be from 6 to 15 characters length";
    }

    return true;
}

export const VideoValidation = (video: Video) => {
    if (!video.title) return "Title can't be empty";
    else if (video.title.length > 200) return "Maximum title length is 200 symbols";
    else if (video.description && video.description.length > 3000) return "Maximum description length is 3000 symbols";
    else if (!video.duration) return "Duration can't be empty";
    else if (video.duration.length > 20) return "Provided duration is too long";
    else if (!video.preview) return "Preview can't be empty";
    else if (!video.video_link) return "Video link can't be empty";

    return true;
}