import React from 'react';
import "./UIButtonsStyles.css"

interface Props {
    label: string,
    onClick: () => void;
}

const PrimaryButton: React.FC<Props> = ({ label, onClick }) => {
    return (
        <button onClick={onClick} className='primary-button button clickable'>
            {label}
        </button>
    );
}

export default PrimaryButton;
