import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Video } from '../../types/VideoType';
import { GetTutorialById } from '../../services/tutorials/TutorialsService';
import Loading from '../../components/common/Layout/Loading';
import NotFound from '../../components/common/Layout/NotFound';
import { FaEye } from "react-icons/fa";
import { GoFileZip } from "react-icons/go";
import { IoTimeOutline } from "react-icons/io5";
import ReactPlayer from 'react-player';
import "./TutorialsPageStyles.css";
import { UserContext } from '../../conexts/UserContext';
import { UserContextType } from '../../types/UserType';
import { CurrentModals } from '../../types/UI/CurrentModals';
import SignInModal from '../../components/specific/Users/SignInModal';

const TutorialPage = () => {
    const { id } = useParams();
    const { user } = useContext(UserContext) as UserContextType;
    const [modalOpen, setModalOpen] = useState<CurrentModals>({
        SignIn: false,
    })
    const [video, setVideo] = useState<Video | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        const fetchVideo = async () => {
            if (id) {
                const response = await GetTutorialById(parseInt(id));

                setVideo(response);
            }

        }
        fetchVideo();
        setLoading(false);
    }, [])

    if (video && !loading) {
        return (
            <div className='page'>
                <div className="single-tutorial-page-wrapper">
                    <div className="youtube-player">
                        <ReactPlayer width={window.screen.width > 1000 ? '70%' : '100%'} height={window.screen.width > 1000 ? '500px' : '300px'} url={video.video_link} controls={true} />
                    </div>


                    <div className="single-tutorial-details">
                        <IoTimeOutline size={20} />
                        <span className="details-created-at">
                            Created at: {video.created_at && new Date(video.created_at).toDateString()}
                        </span>
                        <span className="details-duration">
                            Duration: {video.duration}
                        </span>
                    </div>

                    <div className="single-tutorial-title-wrapper">
                        <h1>{video.title}</h1>
                        <div className="viewed-count">
                            <FaEye size={20} />
                            <span>{video.viewed_count}</span>
                        </div>
                    </div>

                    {video.zip && <a onClick={(e) => {
                        if (!user) {
                            e.preventDefault();
                            setModalOpen(prevState => ({ ...prevState, SignIn: true }));
                        }
                    }} href={video.zip} className='zip-file-download'>
                        <span>
                            Download Zip file
                        </span>
                        <GoFileZip size={30} />
                    </a>}

                    <p className="single-tutorial-desctiption">{video.description}</p>
                </div>
                <SignInModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
            </div>
        );
    }
    else if (loading) {
        return (
            <Loading />
        );
    } else {
        return (
            <NotFound />
        );
    }
}

export default TutorialPage;
