import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Tutorials from '../pages/tutorials/Tutorials';
import TutorialPage from '../pages/tutorials/TutorialPage';

const TutorialsRoute: React.FC = () => {
    return (
        <Routes>
            <Route path='/' element={<Tutorials />} />
            <Route path='/:id' element={<TutorialPage />} />
        </Routes>
    )
}

export default TutorialsRoute;