import { ApiDomain } from "../../config";
import { IUser, IUserCreate } from "../../types/UserType";

export const CreateUser = async (User: IUserCreate) => {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(User)
    };

    try {
        const response = await fetch(`${ApiDomain}/api/users/`, options);
        if (!response.ok) {
            return false;
        }

        return true;

    } catch (error) {
        console.error("Error: ", error);
        return false;
    }
}

export const UpdateUser = async (user: IUser): Promise<boolean> => {
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user),
        credentials: 'include' as RequestCredentials
    };

    try {
        const response = await fetch(`${ApiDomain}/api/users/`, options);
        if (!response.ok) {
            return false;
        }

        return true;

    } catch (error) {
        console.error("Error: ", error);
        return false;
    }
}

export const DeleteUser = async (): Promise<boolean> => {
    const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
        credentials: 'include' as RequestCredentials
    };

    try {
        const response = await fetch(`${ApiDomain}/api/users/`, options);
        if (!response.ok) {
            return false;
        }

        return true;

    } catch (error) {
        console.error("Error: ", error);
        return false;
    }
}