import React, { useContext, useState } from 'react';
import { Video } from '../../../types/VideoType';
import UploadFileButton from '../../common/UI/Buttons/UploadFileButton';
import PrimaryButton from '../../common/UI/Buttons/PrimaryButton';
import DangerButton from '../../common/UI/Buttons/DangerButton';
import { VideoValidation } from '../../../services/Validation/FieldsValidation';
import { AddTutorial, DeleteTutorial, UpdateTutorial } from '../../../services/tutorials/TutorialsService';
import { AlertsContext } from '../../../conexts/AlertsContext';
import { AlertsContextType } from '../../../types/UI/AlertsContextType';

interface Props {
    video: Video;
    setVideo: (video: Video) => void;
    isEdit: boolean;
    setTutorials: React.Dispatch<React.SetStateAction<Video[]>>;
    closeModal: () => void;
}


const TutorialModal: React.FC<Props> = ({ video, setVideo, isEdit, setTutorials, closeModal }) => {
    const { AddAlert } = useContext(AlertsContext) as AlertsContextType
    const [previewB64Prefix, setPreviewB64Prefix] = useState<string>("");
    const [warning, setWarning] = useState<string>("");
    const [preDelete, setPreDelete] = useState<boolean>(false);

    const CreateVideo = async () => {
        const valid = VideoValidation(video);
        if (valid !== true) {
            setWarning(valid as string);
            return;
        }

        const response: Video | null = await AddTutorial(video);
        if (response) {
            setTutorials(prevState => ([response, ...prevState]));
            closeModal();
            AddAlert({ title: "Add new video", content: "Video was added successfully!", type: 'success' });
        } else {
            AddAlert({ title: "Add new video", content: "Error hapened while adding new video", type: 'danger' });
        }
    }

    const UpdateVideoHandle = async () => {
        const valid = VideoValidation(video);
        if (valid !== true) {
            setWarning(valid as string);
            return;
        }

        const response = await UpdateTutorial(video);
        if (response.ok) {
            setTutorials(prevState => {
                return prevState.map((prevVideo) => {
                    if (prevVideo.id === video.id) {
                        return video;
                    } else {
                        return prevVideo;
                    }
                });
            });
            closeModal();
            AddAlert({ title: "Update video", content: "Video was updated successfully!", type: 'success' });
        } else {
            AddAlert({ title: "Update video", content: "Error hapened while updating video", type: 'danger' });
        }

    }

    const DeleteVideoHandle = async () => {
        if (video.id) {
            const response = await DeleteTutorial(video.id);

            if (response.ok) {
                setTutorials(prevState => {
                    return prevState.filter(t => t.id !== video.id);
                });

                closeModal();
                AddAlert({ title: "Delete video", content: "Video was deleted successfully!", type: 'success' });
            } else {
                AddAlert({ title: "Delete video", content: "Error hapened while deleting video", type: 'danger' });
            }

        }
    }

    return (
        <form className='tutorial-form' onSubmit={(e) => e.preventDefault()}>
            <div className="video-preview-image-wrapper">
                <img src={previewB64Prefix + video.preview} alt='preview' className="video-preview-image" height={170} width={300} />
            </div>
            <UploadFileButton
                availableTypes={["image/jpeg", "image/jpg", "image/png", "image/gif",]}
                MaximumFileSize={10240}
                setB64Prefix={setPreviewB64Prefix}
                setFile={(b64String: string) => { setVideo({ ...video, preview: b64String }) }}
                setWarning={setWarning}
                buttonLabel="Upload preview" />

            <label htmlFor="title-input">Title: </label>
            <input
                value={video.title}
                onChange={(e) => { setVideo({ ...video, title: e.target.value }) }}
                type="text" className="form-field" placeholder='Title' id='title-input' />

            <label htmlFor="description-input">Description: </label>
            <textarea
                value={video.description || ""}
                onChange={(e) => { setVideo({ ...video, description: e.target.value }) }}
                className="form-field" placeholder='Description' id="description-input">
            </textarea>

            <label htmlFor="video-link-input">Link to Video: </label>
            <input
                value={video.video_link}
                onChange={(e) => { setVideo({ ...video, video_link: e.target.value }) }}
                type="text" className="form-field" placeholder='Link' id='video-link-input' />

            {video.zip && <p className="zip-filename">Zip uploaded successfully</p>}
            <UploadFileButton
                availableTypes={[
                    "application/zip",
                    "application/x-rar-compressed",
                    "application/x-7z-compressed",
                    "application/x-tar",
                    "application/gzip",
                    "application/x-bzip2",
                    "application/x-xz",
                    "application/x-lzip",
                    "application/x-lzma",
                    "application/vnd.ms-cab-compressed",
                    "application/x-iso9660-image",
                    "application/x-arj",
                    "application/x-cpio",
                    "application/x-compress",
                    "application/vnd.debian.binary-package",
                    "application/x-rpm",
                    "application/x-lz4",
                    "application/zstd",
                    "application/x-apple-diskimage",
                    "application/x-lzh-compressed",
                    "application/x-zip-compressed"
                ]}
                MaximumFileSize={102400}
                setB64Prefix={(p: string) => { }}
                setFile={(b64String: string) => { setVideo({ ...video, zip: b64String }) }}
                setWarning={setWarning}
                buttonLabel="Upload zip file" />

            <label htmlFor="duration-input">Video duration: </label>
            <input
                value={video.duration}
                onChange={(e) => { setVideo({ ...video, duration: e.target.value }) }}
                type="string" className="form-field" placeholder='Duration' id='duration-input' />

            {warning && <p className='warning'>{warning}</p>}

            <PrimaryButton onClick={isEdit ? UpdateVideoHandle : CreateVideo} label={isEdit ? "Update video" : "Add video"} />
            {isEdit &&
                <div className="delete-section">
                    {!preDelete ? <DangerButton onClick={() => { setPreDelete(true) }} label='Delete video' /> :
                        <div className='predelete'>
                            <p className="warning" style={{ color: 'red' }}>Are you sure?</p>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: 200 }}>
                                <DangerButton onClick={DeleteVideoHandle} label='Yes' />
                                <PrimaryButton onClick={() => { setPreDelete(false) }} label='No, thnaks' />
                            </div>
                        </div>
                    }
                </div>
            }
        </form>
    );
}

export default TutorialModal;