import React, { createContext, useEffect, useState } from "react";
import { IUser, UserContextType } from "../types/UserType";
import { GetMe, GitHubSignIn, GoogleSignIn, Logout } from "../services/auth/AuthServices";

export const UserContext = createContext<UserContextType | null>(null);

const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<IUser | null>(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const newUser: null | IUser = await GetMe();

                if (newUser) {
                    setUser(newUser);
                }
            } catch (error) {
                console.error('Error fetching user:', error);
                setUser(null);
            }
        };

        fetchUser();

    }, []);

    const LogoutUser = async () => {
        await Logout();
        setUser(null);
    }

    const SingInWithGoogle = async (accessToken: string): Promise<boolean> => {
        const response = await GoogleSignIn(accessToken);
        const newUser: null | IUser = await GetMe();

        setUser(newUser);
        return response;
    }

    const SignInWithGitHub = async (code: string): Promise<boolean> => {
        const response = await GitHubSignIn(code);
        const newUser: null | IUser = await GetMe();

        setUser(newUser);
        return response;
    }

    const UpdateUserInfo = async () => {
        const newUser: null | IUser = await GetMe();
        setUser(newUser);
    }

    return (
        <UserContext.Provider value={{ user, LogoutUser, SingInWithGoogle, SignInWithGitHub, UpdateUserInfo }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;