import React from 'react';
import { FaYoutube } from "react-icons/fa";
import "./Layout.css";

const Footer = () => {
    return (
        <div className='footer'>
            <div className="footer-icons">
                <a target='_blank' href='https://www.youtube.com/channel/UCJjA2Im7Vv0WzHBFdULLsOg' className="footer-icon-youtube">
                    <FaYoutube size={50} />
                </a>
            </div>
        </div>
    );
}

export default Footer;
