import { ApiDomain } from "../../config";
import { IUser } from "../../types/UserType";

export const GetMe = async () => {
    const options = {
        method: 'GET',
        credentials: 'include' as RequestCredentials
    };

    try {
        const response = await fetch(`${ApiDomain}/api/auth/me`, options);
        if (!response.ok) {
            return null;
        }
        const data = await response.json();

        const user: IUser = {
            Id: data.id,
            Email: data.email,
            Name: data.name,
            Avatar: data.avatar,
            Provider: data.provider,
            CreatedAt: new Date(data.createdAt),
            isAdmin: data.isAdmin
        };

        return user;

    } catch (error) {
        console.error('Get me Error:', error);
        return null;
    }
}

// export const SignIn = async (Email: string, Password: string) => {
//     if (!Email || !Password) return null;

//     const options = {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json"
//         },
//         body: JSON.stringify({
//             Email: Email,
//             Password: Password
//         })
//     };

//     try {
//         const response = await fetch(`${ApiDomain}/api/auth/`, options);
//         if (!response.ok) {
//             return null;
//         }
//         const data = await response.json();
//         return data.token;
//     } catch (error) {
//         console.error('Error:', error);
//         return null;
//     }

// }

export const GoogleSignIn = async (accessToken: string) => {
    const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": accessToken
        },
        credentials: 'include' as RequestCredentials
    };

    try {
        const response = await fetch(`${ApiDomain}/api/auth/google`, options);
        if (!response.ok) {
            return false;
        }

        return true;
    } catch (error) {
        console.error('Sign in Error:', error);
        return false;
    }

}

export const GitHubSignIn = async (code: string): Promise<boolean> => {
    try {
        await fetch(`${ApiDomain}/api/auth/github/callback`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code }),
            credentials: 'include' as RequestCredentials
        });

        return true;
    } catch (error) {
        console.error('Sign in Error:', error);
        return false;
    }
}

export const Logout = async () => {
    const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
        credentials: 'include' as RequestCredentials
    };

    try {
        await fetch(`${ApiDomain}/api/auth/logout`, options);
    } catch (error) {
        console.error('Logout Error: ', error);

    }
}

