import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home/Home';
import Profile from '../pages/home/Profile';
import PrivacyPolicy from '../pages/home/PrivacyPolicy';
import TermsOfService from '../pages/home/TermsOfService';

const HomeRoute: React.FC = () => {
    return (
        <Routes>
            <Route index path='/' element={<Home />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/Terms-of-service' element={<TermsOfService />} />
        </Routes>
    )
}

export default HomeRoute;